



























































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import moment from 'moment'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { UserRole } from '@/shared/enum/general-enum'
import { hasUserRole } from '@/shared/utils/userRoleCheck'

export default defineComponent({
    props: {
        approvalContent: {
            type: Object,
        },
        disable: {
            type: Boolean,
        },
        assessmentDetails: {
            type: Object,
        },
        isMSR: {
            type: Boolean,
        },
    },
    setup(props) {
        const { t } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        // get logged in user info
        const loggedInUser: { user: { id; organisations } } = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.USER, {
            decrypt: true,
        })
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const isUserValidator = hasUserRole(loggedInUser.user.organisations, UserRole.VALIDATOR)
        const assessmentDetails = props.assessmentDetails
        // get current organization id
        const organisationId = utils.ls.get(variables.LOCAL_STORAGE_ITEMS.ORGANISATION, {
            decrypt: true,
        })
        // assessment approve form
        const assessmentApprovalForm = ref({
            // the reviewer is the logged-in user (but cannot be the executed by user, then it's empty)
            VALIDATION_BY:
                assessmentDetails &&
                assessmentDetails.executedBy &&
                assessmentDetails.executedBy.id == userId
                    ? 0
                    : userId,
            VALIDATION_DATE: new Date(),
            VALIDATION_REMARK: '',
        })
        const errors = ref({
            VALIDATION_BY: { missingValue: false },
            VALIDATION_DATE: { missingValue: false, invalidValue: false },
        })
        const labelInfomationOpValidatedBy = ref(null)
        const labelInfomationOpValidationDate = ref(null)
        const validators = ref([])

        const validateDate = () => {
            // validation date cannot be in the future or be before executionDate
            const executionDate = new Date(assessmentDetails.executionDate)
            return assessmentApprovalForm.value.VALIDATION_DATE <= executionDate
        }

        const getValidators = () => {
            validators.value = []
            // exclude assessment executed user from users list
            const filterdUsers = users.value.filter(
                (user) =>
                    user.id !== (assessmentDetails.executedBy && assessmentDetails.executedBy.id)
            )
            filterdUsers.map((user) => {
                // get user loggedin organisation (current org)
                const currentOrganisation = user.organisations.filter(
                    (org) => org.id === Number(organisationId)
                )
                // get users who are available in current organisation with 'Employee' role
                const filterdValidator =
                    currentOrganisation[0] &&
                    !!currentOrganisation[0].roles.find((role) => role.name === UserRole.VALIDATOR)
                if (filterdValidator) {
                    validators.value.push(user)
                }
            })
        }

        //  validating form
        const validateForm = () => {
            errors.value.VALIDATION_BY.missingValue = utils.validateFields(
                assessmentApprovalForm.value.VALIDATION_BY,
                'number'
            )
            errors.value.VALIDATION_DATE.missingValue = utils.validateFields(
                assessmentApprovalForm.value.VALIDATION_DATE,
                'string'
            )

            errors.value.VALIDATION_DATE.invalidValue = validateDate()
        }

        // clear fields and clear errors
        const cancelForm = () => {
            assessmentApprovalForm.value = {
                VALIDATION_BY:
                    assessmentDetails &&
                    assessmentDetails.executedBy &&
                    assessmentDetails.executedBy.id == userId
                        ? 0
                        : userId,
                VALIDATION_DATE: new Date(),
                VALIDATION_REMARK: '',
            }
            errors.value = {
                VALIDATION_BY: { missingValue: false },
                VALIDATION_DATE: { missingValue: false, invalidValue: false },
            }
        }

        // open information overlay panel
        const toggleInformation = (event: MouseEvent, field) => {
            utils.findMousePointerLocationOP(event)

            //  toggle overlay popup for each label field
            field.toggle(event)
        }

        const resetForm = () => {
            assessmentApprovalForm.value = {
                // the reviewer is the logged-in user (but cannot be the executed by user, then it's empty)
                VALIDATION_BY:
                    assessmentDetails &&
                    assessmentDetails.executedBy &&
                    assessmentDetails.executedBy.id == userId
                        ? 0
                        : userId,
                VALIDATION_DATE: new Date(),
                VALIDATION_REMARK: '',
            }
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return !props.approvalContent
        })
        //  watch for assessment content changes
        watch(
            () => props.assessmentDetails,
            () => {
                resetForm()
                if (props.assessmentDetails) {
                    getValidators()
                    assessmentApprovalForm.value.VALIDATION_BY =
                        assessmentDetails.executedBy && assessmentDetails.executedBy.id == userId
                            ? 0
                            : userId
                }
            },
            { immediate: true }
        )

        return {
            t,
            language,
            assessmentApprovalForm,
            validators,
            moment,
            validateForm,
            errors,
            cancelForm,
            labelInfomationOpValidatedBy,
            labelInfomationOpValidationDate,
            toggleInformation,
            utils,
            isEditableMode,
            isUserValidator,
            userId
        }
    },
})
