






























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import { useState } from '@/shared/mixins/helpers'
import { computed, defineComponent, ref, watch } from '@vue/composition-api'
import { useI18n } from 'vue-i18n-composable'
import router from '@/router'
import moment from 'moment'
import CircleCmp from '@/shared/components/CircleCmp.vue'
import controlAssessmentData from '@/assets/data/control-assessment-data.json'
import utils from '@/shared/mixins/utils'
import variables from '@/shared/variables'
import { Interval } from '@/shared/enum/general-enum'
import { Effectiveness } from '@/shared/enum/asset-control-assessment-enum'
import controldetailsDropdownData from '@/assets/data/controls-data.json'
import type { UserDTO } from '@/dto/backend-response/usersDTO'
import InfoIcon from '../InfoIcon.vue'
import DocumentationLink from '@/shared/components/DocumentationLink.vue'
import { hasUserRole } from '@/shared/utils/userRoleCheck'
import { UserRole } from '@/shared/enum/general-enum'

interface AssessmentFormDTO {
    EXECUTED_BY: number;
    DESIGN: string;
    DESIGN_DATE: Date;
    DESIGN_REMARK: string;
    EXISTENCE: string;
    EXISTENCE_DATE: Date;
    EXISTENCE_REMARK: string;
    OPERATIONAL_EFFECTIVENESS: string;
    OPERATIONAL_EFFECTIVENESS_REMARK: string;
    OVERALL_CONCLUSION_REMARK: string;
    OVERALL: string;
    MATURITY: number;
    MATURITY_AMBITION: number;
    IMPLEMENTATION_STATUS: string;
    REMAINING_RISK_ACCEPTED: number;
    REMAINING_RISK_ACCEPTED_REMARK: string;
    DOCUMENTATION_URL: string;
    ACTIONPLAN_REQUIRED: number;
    ACTIONPLAN_REMARK: string;
    EXECUTION_DATE: Date;
    DATE_NEXT_ASSESSMENT: Date;
    ASSESSMENT_EXPLANATION: string;
    OP_START_DATE: Date;
    OP_END_DATE: Date;
    ASSESSMENT_INTERVAL_VALUE: number;
    ASSESSMENT_INTERVAL_UNIT: number;
}

const initializeForm = (): AssessmentFormDTO => ({
    EXECUTED_BY: utils.getLoggedInUser().id,
    DESIGN: '',
    DESIGN_DATE: new Date(),
    DESIGN_REMARK: '',
    EXISTENCE: '',
    EXISTENCE_DATE: new Date(),
    EXISTENCE_REMARK: '',
    OPERATIONAL_EFFECTIVENESS: '',
    OPERATIONAL_EFFECTIVENESS_REMARK: '',
    OVERALL_CONCLUSION_REMARK: '',
    OVERALL: '',
    MATURITY: 0,
    MATURITY_AMBITION: 0,
    IMPLEMENTATION_STATUS: 'NO_STATUS',
    REMAINING_RISK_ACCEPTED: 0,
    REMAINING_RISK_ACCEPTED_REMARK: '',
    DOCUMENTATION_URL: '',
    ACTIONPLAN_REQUIRED: 0,
    ACTIONPLAN_REMARK: '',
    EXECUTION_DATE: new Date(),
    DATE_NEXT_ASSESSMENT: new Date(),
    ASSESSMENT_EXPLANATION: '',
    OP_START_DATE: new Date(),
    OP_END_DATE: new Date(),
    ASSESSMENT_INTERVAL_VALUE: 0,
    ASSESSMENT_INTERVAL_UNIT: 0,
})

interface AssessmentFormErrors {
    EXECUTED_BY: { expire: boolean };
    DESIGN: { missingValue: boolean };
    EXISTENCE: { missingValue: boolean };
    OPERATIONAL_EFFECTIVENESS: { missingValue: boolean };
    OVERALL: { missingValue: boolean };
    MATURITY: { missingValue: boolean };
    MATURITY_AMBITION: { missingValue: boolean };
    REMAINING_RISK_ACCEPTED: { missingValue: boolean };
    ACTIONPLAN_REQUIRED: { missingValue: boolean };
    OP_END_DATE: { missingValue: boolean; invalidValue: boolean };
    DATE_NEXT_ASSESSMENT: { invalidValue: boolean };
    DOCUMENTATION_URL: { invalidValue: boolean };
    NO_TEST_PERFORMED: { noTestFound: boolean };
}

const initializeErrors = (): AssessmentFormErrors => ({
    EXECUTED_BY: { expire: false },
    DESIGN: { missingValue: false },
    EXISTENCE: { missingValue: false },
    OPERATIONAL_EFFECTIVENESS: { missingValue: false },
    OVERALL: { missingValue: false },
    MATURITY: { missingValue: false },
    MATURITY_AMBITION: { missingValue: false },
    REMAINING_RISK_ACCEPTED: { missingValue: false },
    ACTIONPLAN_REQUIRED: { missingValue: false },
    OP_END_DATE: { missingValue: false, invalidValue: false },
    DATE_NEXT_ASSESSMENT: { invalidValue: false },
    DOCUMENTATION_URL: { invalidValue: false },
    NO_TEST_PERFORMED: { noTestFound: false },
})

const initializeFormFromSavedAssessment = (assessment): AssessmentFormDTO => {
    // true == yes(1), false == no(2)
    const TRUE = 1
    const FALSE = 2

    return {
        EXECUTED_BY: assessment.executedBy && assessment.executedBy?.id,
        DESIGN: assessment.design,
        DESIGN_REMARK: assessment.designRemark,
        EXISTENCE: assessment.existence,
        EXISTENCE_REMARK: assessment.existenceRemark,
        OPERATIONAL_EFFECTIVENESS: assessment.operationalEffectiveness,
        OPERATIONAL_EFFECTIVENESS_REMARK: assessment.operationalEffectivenessRemark,
        OVERALL: assessment.overallConclusion
            ? Effectiveness.EFFECTIVE
            : Effectiveness.NOT_EFFECTIVE,
        OVERALL_CONCLUSION_REMARK: assessment.overallConclusionRemark,
        MATURITY: assessment.maturity,
        MATURITY_AMBITION: assessment.maturityAmbition,
        IMPLEMENTATION_STATUS: assessment?.implementationStatus ?? 'NO_STATUS',
        REMAINING_RISK_ACCEPTED: assessment.remainingRiskAccepted ? TRUE : FALSE,
        REMAINING_RISK_ACCEPTED_REMARK: assessment.remainingRiskAcceptedRemark,
        DOCUMENTATION_URL: assessment.documentationUrl,
        ACTIONPLAN_REQUIRED: assessment.actionplanRequired ? TRUE : FALSE,
        ACTIONPLAN_REMARK: assessment.actionplanExplanation,
        EXECUTION_DATE: moment(assessment.executionDate).toDate(),
        DATE_NEXT_ASSESSMENT: moment(assessment.dateNextAssessment).toDate(),
        ASSESSMENT_EXPLANATION: assessment.assessmentExplanation,
        OP_START_DATE: assessment.opStartDate
            ? moment(assessment.opStartDate).toDate()
            : new Date(),
        OP_END_DATE: assessment.opEndDate ? moment(assessment.opEndDate).toDate() : new Date(),
        DESIGN_DATE: assessment.designSetDate
            ? moment(assessment.designSetDate).toDate()
            : new Date(),
        EXISTENCE_DATE: assessment.existenceSetDate
            ? moment(assessment.existenceSetDate).toDate()
            : new Date(),
        ASSESSMENT_INTERVAL_VALUE: assessment.assessmentIntervalValue,
        ASSESSMENT_INTERVAL_UNIT: assessment.assessmentIntervalUnit,
    }
}

const initializeFormFromSourceAssessment = (source): AssessmentFormDTO => {
    const defaultValues = initializeForm()
    // Copy data from the form, but reset some fields to their default values
    return {
        ...initializeFormFromSavedAssessment(source),
        EXECUTION_DATE: defaultValues.EXECUTION_DATE,
        OP_START_DATE: defaultValues.OP_START_DATE,
        OP_END_DATE: defaultValues.OP_END_DATE,
        DESIGN_DATE: defaultValues.DESIGN_DATE,
        EXISTENCE_DATE: defaultValues.EXISTENCE_DATE,
    }
}

const validateAssessmentForm = (
    form: AssessmentFormDTO,
    employees: Array<UserDTO>,
    assessmentType: string
): AssessmentFormErrors => {
    const errors = initializeErrors()
    // missingValue checks
    errors.DESIGN.missingValue = utils.validateFields(form.DESIGN, 'string')
    errors.EXISTENCE.missingValue = utils.validateFields(form.EXISTENCE, 'string')
    errors.OPERATIONAL_EFFECTIVENESS.missingValue = utils.validateFields(
        form.OPERATIONAL_EFFECTIVENESS,
        'string'
    )
    errors.OVERALL.missingValue = utils.validateFields(form.OVERALL, 'string')

    errors.MATURITY.missingValue = utils.validateFields(form.MATURITY, 'number')

    errors.MATURITY_AMBITION.missingValue = utils.validateFields(form.MATURITY_AMBITION, 'number')

    errors.REMAINING_RISK_ACCEPTED.missingValue = utils.validateFields(
        form.REMAINING_RISK_ACCEPTED,
        'number'
    )

    // there are no action plan available in asset assessment
    if (assessmentType === 'asset') {
        errors.ACTIONPLAN_REQUIRED.missingValue = false
    } else {
        errors.ACTIONPLAN_REQUIRED.missingValue = utils.validateFields(
            form.ACTIONPLAN_REQUIRED,
            'number'
        )
    }

    // store Design, Existence and OE values in a separate array
    const bivInputs = [form.DESIGN, form.EXISTENCE, form.OPERATIONAL_EFFECTIVENESS]
    // check if user has selected any values for Design, Existence and OE dropdown fields
    if (bivInputs.filter((biv) => biv === '').length === 0) {
        // check for "NOT_TESTED" values in bivInputs
        const effectivenessCheck = bivInputs.filter(
            (biv) => biv === String(Effectiveness.NOT_TESTED)
        )
        /* if bivInputs has NOT_TESTED values for all 3 fields display validation error message 
        asking user to select Effective/Non-Effective values at least one from Design, Existence and OE */
        effectivenessCheck.length === 3
            ? (errors.NO_TEST_PERFORMED.noTestFound = true)
            : (errors.NO_TEST_PERFORMED.noTestFound = false)
    }

    // date_next_assessment >= execution_date
    if (form.DATE_NEXT_ASSESSMENT >= form.EXECUTION_DATE) {
        errors.DATE_NEXT_ASSESSMENT.invalidValue = false
    } else {
        errors.DATE_NEXT_ASSESSMENT.invalidValue = true
    }
    // start_date =< end_date
    if (form.OP_END_DATE >= form.OP_START_DATE) {
        errors.OP_END_DATE.invalidValue = false
    } else {
        errors.OP_END_DATE.invalidValue = true
    }

    /* check whether selected owner is expired or not.
    Eventhough user cannot select an expired user, this will need if the already selected user is an expired user.
    In this case, it will ask to select an active user
        */
    const executedBy = employees.find((employee) => employee.id === form.EXECUTED_BY)
    if (executedBy) errors.EXECUTED_BY.expire = executedBy.userExpired
    return errors
}

const intervalFromNow = (intervalUnit: Interval, intervalValue: number): Date => {
    const today = moment()
    if (intervalUnit && intervalValue) {
        // If both are defined, compute the next assessment date based on their value
        switch (intervalUnit) {
            case Interval.day:
                return today.add(intervalValue, 'day').toDate()
            case Interval.week:
                return today.add(intervalValue, 'week').toDate()
            case Interval.month:
                return today.add(intervalValue, 'month').toDate()
            case Interval.quarter:
                return today.add(intervalValue, 'quarter').toDate()
            case Interval.year:
                return today.add(intervalValue, 'year').toDate()
        }
    }
    // Ohterwise, return today as a fallback
    return today.toDate()
}

export default defineComponent({
    props: {
        assessmentDetails: {
            type: undefined,
        },
        editable: {
            type: Boolean,
        },
        entityDetails: {
            type: undefined,
        },
        actionEdit: {
            type: Boolean,
        },
        assessmentType: {
            type: String,
        },
        controlList: {
            type: Array,
        },
        isMSR: {
            type: Boolean,
        },
        newAssessmentSource: {
            // Todo: Type this correctly. Currently no DTO types are defiend for these queries.
            // Can be: ControlAssessmentDetailsDTO | AssetControlAssessmentDetailsDTO | MSRAssessmentDetailsDTO
            type: Object,
        },
    },
    components: {
        CircleCmp,
        InfoIcon,
        DocumentationLink,
    },
    setup(props) {
        const { t, tc } = useI18n()
        const { language } = useState(['language'])
        const { users } = useState(['users'])
        const employees = computed(() => utils.getEmployees(users.value))
        const assessmentForm = ref(initializeForm())
        const errors = ref(initializeErrors())
        const executedByUser = ref(0)
        // get logged in user info
        const loggedInUser: { user: { id: number; organisations } } | null = utils.ls.get(
            variables.LOCAL_STORAGE_ITEMS.USER,
            {
                decrypt: true,
            }
        )
        // get user id of logged in user
        const userId = loggedInUser && loggedInUser.user.id
        const isUserEmployee = hasUserRole(loggedInUser.user.organisations, UserRole.EMPLOYEE)
        // Watch for props changes, and set the form data accordingly
        watch(
            [
                () => props.assessmentDetails,
                () => props.entityDetails,
                () => props.newAssessmentSource,
            ],
            ([savedAssessment, relatedEntity, newAssessmentSource]) => {
                if (savedAssessment) {
                    // If a saved assessment is provided, load its data into the form
                    assessmentForm.value = initializeFormFromSavedAssessment(savedAssessment)
                } else {
                    // Creating a new assessment
                    if (newAssessmentSource) {
                        // Copy data from a source assessment
                        const assessmentFormValues =  initializeFormFromSourceAssessment(newAssessmentSource)
                        assessmentForm.value =
                            {...assessmentFormValues, EXECUTED_BY:  isUserEmployee ? userId : assessmentFormValues.EXECUTED_BY}
                    } else {
                        // No source assessment provided, so initialize
                        assessmentForm.value = initializeForm()
                    }
                    if (relatedEntity) {
                        // Because this is a new assessment, set the next assessment date based on
                        // current date and the assessment interval of the related entity.
                        assessmentForm.value.DATE_NEXT_ASSESSMENT = intervalFromNow(
                            relatedEntity.assessmentIntervalUnit,
                            relatedEntity.assessmentIntervalValue
                        )
                    }
                }
            },
            { immediate: true }
        )

        //  validating form
        const validateForm = () => {
            errors.value = validateAssessmentForm(
                assessmentForm.value,
                employees.value,
                props.assessmentType
            )
        }

        // go to action plan
        const goToActionPlan = (id) => {
            router.push({ name: 'ActionPlan', params: { actionplanId: id } })
        }

        // create new action plan
        const createActionPlan = () => {
            router.push({ name: 'CreateActionPlan' })
        }

        const gotoMaturityInfo = () => router.push({ name: 'Maturity' })

        // on click of cancel, form and errors will be reset
        const cancelForm = () => {
            assessmentForm.value = initializeForm()
            errors.value = initializeErrors()
            assessmentForm.value.DATE_NEXT_ASSESSMENT = intervalFromNow(
                props.entityDetails?.executionIntervalUnit,
                props.entityDetails?.executionIntervalValue
            )
        }

        // page will be in editable mode under following conditions
        const isEditableMode = computed(() => {
            return !props.assessmentDetails || props.editable
        })

        /*Disable Executor selection when:
            1. Editing Assessment && logged in user is not the executor of the assessment
            2. Avoid disable on New or Pre-filled assessment creation
        */
        const isDisableExecuterField = computed(() => {
            if (!props.assessmentDetails) return false
            return props.assessmentDetails && props.assessmentDetails?.executedBy.id !== userId
        })

        const maturityDropdown = computed(() =>
            controlAssessmentData.MATURITY.map((maturity) => ({
                score: maturity.SCORE,
                value: t(maturity.VALUE, language.value),
            }))
        )
        const effectiveDropdown = computed(() =>
            controlAssessmentData.EFFECTIVENESS.map((effective) => ({
                id: effective.KEY,
                value: t(effective.VALUE, language.value),
            }))
        )
        const overallDropdown = computed(() =>
            controlAssessmentData.OVERALL.map((overall) => ({
                id: overall.KEY,
                value: t(overall.VALUE, language.value),
            }))
        )
        const riskAcceptedDropdown = computed(() =>
            controlAssessmentData.ACTIONPLAN_REQ.map((acceptance) => ({
                id: acceptance.ID,
                value: t(acceptance.VALUE, language.value),
            }))
        )
        const actionPlanReqDropdown = computed(() =>
            controlAssessmentData.RISK_ACCEPTANCE.map((actionplanreq) => ({
                id: actionplanreq.ID,
                value: t(actionplanreq.VALUE, language.value),
            }))
        )
        const intervalDropDown = computed(() =>
            controldetailsDropdownData.INTERVALS.map((interval) => ({
                id: interval.ID,
                value: tc(interval.VALUE, 0),
            }))
        )
        const maturityExplanation = computed(
            () =>
                controlAssessmentData.MATURITY.find(
                    (val) => val.SCORE === props.assessmentDetails?.maturity
                )?.VALUE ?? ''
        )
        const maturityAmbitionExplanation = computed(
            () =>
                controlAssessmentData.MATURITY.find(
                    (val) => val.SCORE === props.assessmentDetails?.maturityAmbition
                )?.VALUE ?? ''
        )

        const implementationStatusTranslationKey = (value: string): string => {
            return controlAssessmentData.IMPLEMENTATION_STATUS.find(
                (option) => option.value === value
            ).labelKey
        }

        return {
            t,
            language,
            assessmentForm,
            users,
            maturityDropdown,
            implementationStatusDropdown: controlAssessmentData.IMPLEMENTATION_STATUS,
            moment,
            maturityExplanation,
            maturityAmbitionExplanation,
            effectiveDropdown,
            validateForm,
            errors,
            cancelForm,
            goToActionPlan,
            createActionPlan,
            gotoMaturityInfo,
            riskAcceptedDropdown,
            actionPlanReqDropdown,
            variables,
            employees,
            controlAssessmentData,
            implementationStatusTranslationKey,
            utils,
            Effectiveness,
            overallDropdown,
            intervalDropDown,
            controldetailsDropdownData,
            isEditableMode,
            isDisableExecuterField,
            isUserEmployee
        }
    },
})
